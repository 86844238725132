export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 200,
        label: "Home",
        icon: "uil-home",
        link: "/"
    },
    {
        id: 2,
        label: "Wheel",
        icon: "uil-bitcoin-circle",
        link: "/wheel"
    },
    {
        id: 2200,
        label: "Whale Roller",
        icon: "uil-university",
        link: "/whale-roller"
    },
    {
        id: 22,
        label: "Low Roller",
        icon: "uil-bitcoin-circle",
        link: "/low-roller"
    },
    {
        id: 3,
        label: "Instructions",
        icon: "uil-info-circle",
        link: "/instructions"
    },
    {
        id: 4,
        label: "Terms and Conditions",
        icon: "uil-file-check-alt",
        link: "/terms"
    },
];

